<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('org_pro.organization')"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpProgressType.org_id"
                          :options="organizationList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Application Type" vid="application_type_id " rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="application_type_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{ $t('irrigation_config.application_type') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpProgressType.application_type_id"
                          :options="applicationTypeList"
                          id="application_type_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Pump Type Name" vid="pump_type_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('irrigation_config.pump_type')"
                        label-for="pump_type_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('irrigation_config.pump_type')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpProgressType.pump_type_id"
                          :options="pumpTypeList"
                          id="pump_type_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <b-row v-for="(step, index) in pumpProgressType.steps" :key="index">
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Step Name (En)" vid="step_name" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="6"
                            :label="$t('irrigation_config.step_name')"
                            label-for="step_name"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{$t('irrigation_config.step_name')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-input
                              :id="String(index)"
                              v-model="step.step_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              style="margin-left:5px"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="5" sm="5">
                        <ValidationProvider name="Step Name (Bn)" vid="step_name_bn" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="6"
                            :label="$t('irrigation_config.step_name_bn')"
                            label-for="step_name_bn"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            {{$t('irrigation_config.step_name_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              :id="String(index)"
                              v-model="step.step_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="1" sm="1">
                        <b-button v-show="index == pumpProgressType.steps.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                        <b-button v-show="index || ( !index && pumpProgressType.steps.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpProgressTypeStore, pumpProgressTypeUpdate } from '../../api/routes'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getPumpProgressTypeData()
      this.pumpProgressType = tmp
        const tempData = tmp.steps.map(item => {
          return { step_name: item.step_name, step_name_bn: item.step_name_bn }
      })
      this.pumpProgressType.steps = tempData
    }
    this.getApplicationTypelist()
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      pumpProgressType: {
        org_id: 0,
        pump_type_id: 0,
        application_type_id: 0,
        steps: [
            {
              step_name: '',
              step_name_bn: ''
            }
        ]
      },
      pumpTypeList: [],
      applicationTypeList: [],
      applicationTypes: [
        { value: 1, text: 'New', text_bn: 'নতুন' },
        { value: 2, text: 'Resunk', text_bn: 'পুনরায়' }
      ]
    }
  },
  watch: {
    'pumpProgressType.org_id': function (newVal, oldVal) {
      this.pumpTypeList = this.getPumpTypeList(newVal)
    }
  },
  methods: {
    getPumpProgressTypeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.pumpProgressType.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${pumpProgressTypeUpdate}/${this.id}`, this.pumpProgressType)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpProgressTypeStore, this.pumpProgressType)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
          this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getApplicationTypelist () {
      this.applicationTypeList = this.applicationTypes.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getPumpTypeList (orgId) {
      const pumpTypeList = this.$store.state.IrriConfig.commonObj.pumpType
      if (orgId) {
        return pumpTypeList.filter(pumpType => (pumpType.org_id === orgId && pumpType.status === 0))
      }
      return pumpTypeList
    },
    add () {
      const stepName = {
        step_name: '',
        step_name_bn: ''
      }
      this.pumpProgressType.steps.push(stepName)
    },
    remove (key) {
      this.pumpProgressType.steps.splice(key, 1)
    }
  }
}
</script>
