<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
                <b-col md="12" class="table-responsive">
                  <table class="table">
                    <tr>
                      <th>{{ $t('org_pro.organization') }}</th>
                      <td v-if="localeLang === 'bn'">{{ pumpInstallationProgress.org_name_bn }}</td>
                      <td v-else>{{ pumpInstallationProgress.org_name }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('irrigation_config.application_type') }}</th>
                      <td v-if="localeLang === 'bn'">{{ pumpInstallationProgress.application_type_name_bn }}</td>
                      <td v-else>{{ pumpInstallationProgress.application_type_name }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('irrigation_config.pump_type_namel') }}</th>
                      <td v-if="localeLang === 'bn'">{{ pumpInstallationProgress.pump_type.pump_type_name_bn }}</td>
                      <td v-else>{{ pumpInstallationProgress.pump_type.pump_type_name }}</td>
                    </tr>
                  </table>
                  <b-table bordered hover :items="pumpInstallationProgress.steps" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                  </b-table>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
// import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  props: ['item'],
  components: {
  },
  created () {
      const tmp = this.item
      this.pumpInstallationProgress = Object.assign({}, tmp)
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      pumpInstallationProgress: []
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.$store.state.IrriConfig.pumpProgressTypes
      return objectData.filter(data => data.org_id === this.item.org_id && data.pump_type_id === this.item.pump_type_id && data.application_type_id === this.item.application_type_id)
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('irrigation_config.step_namel'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'step_name_bn' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'step_name' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    getOrgName () {
      this.pumpInstallationProgress.map((item, key) => {
        const orgList = this.$store.state.commonObj.organizationProfileList
        return orgList.find(orgItem => orgItem.value === item[key])
      })
    }
  }
}
</script>
